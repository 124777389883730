// src/Header.js

import logoImg from '../../assets/imagens/logo.jpg'
import logoImg1 from '../../assets/imagens/logo_sem_fundo.png'
import React, { useState, useEffect, useRef } from 'react';
import './Header.css';
import { FaShoppingCart, FaUser, FaBars, FaSearch, FaTimes,FaWhatsapp } from 'react-icons/fa';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [freteGratis, setFreteGratis] = useState(true);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  const closeFreteGratis = () => {
    setFreteGratis(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="header">
      <div className={` ${freteGratis ? 'freteGratis' : 'oculto'}`}>
        <div>Site em manutenção!{/*Frete grátis em todo os painéis. Aproveite agora!!!*/}</div>
        <FaTimes className="icon" onClick={closeFreteGratis} />
      </div>
      <a href='https://wa.me/5531991049112' className='whatsAppIcon'>
        <FaWhatsapp className='icon' size={100}/>
      </a>
      <div className={`header-top  ${freteGratis ? 'marginFreteGratis' : ''}`}>
        <img src={logoImg} alt="Logo Street Fighter" height='48px'/>
        <div className="search-cart-login">
          <div className="search-container">
            <FaSearch className="search-icon" />
            <input type="text" className="search" placeholder="Buscar..." />
          </div>
          <FaShoppingCart className="icon" />
          <FaUser className="icon" />
        </div>
      </div>
      <nav className="header-bottom">
        <a href="/" className="nav-item">Inicio</a>
        <a href="/paineis" className="nav-item">Painéis</a>
        <a href="/sensores" className="nav-item">Sensores</a>
        <a href="/molduras" className="nav-item">Molduras</a>
        <a href="/acessorios" className="nav-item">Acessórios</a>
        <a href="/gabaritos" className="nav-item">Gabaritos</a>
        <a href="/instaladores" className="nav-item">Instaladores</a>
        

      </nav>
      <div className={`header-mobile ${freteGratis ? 'marginFreteGratis' : ''}`}>
        <div>
          <FaBars className="icon" onClick={toggleMenu} />
          <FaSearch className="icon" />
        </div>
        <img src={logoImg1} alt="Logo Street Fighter" height='64px'/>
        <div>
          <FaShoppingCart className="icon" />
          <FaUser className="icon" />
        </div>
      </div>
      <nav ref={menuRef} className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>

        <div>
          <img src={logoImg1} alt="Logo Street Fighter" height='64px'/>
          <FaTimes className="close-icon" onClick={closeMenu} />
        </div>
        <a href="/" className="nav-item" onClick={closeMenu}>Inicio</a>
        <a href="/paineis" className="nav-item" onClick={closeMenu}>Painéis</a>
        <a href="/sensores" className="nav-item" onClick={closeMenu}>sensores</a>
        <a href="/molduras" className="nav-item" onClick={closeMenu}>Molduras</a>
        <a href="/acessorios" className="nav-item" onClick={closeMenu}>Acessórios</a>
        <a href="/gabarito" className="nav-item" onClick={closeMenu}>Gabarito</a>
        <a href="/instaladores" className="nav-item" onClick={closeMenu}>Instaladores</a>
      </nav>
    </header>
  );
}

export default Header;
