// src/Home.js

import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
import Carousel from '../Carousel';
import img1 from '../../assets/imagens/capa-site.png'
import CafeRacer from '../../assets/imagens/produtos/CafeRacer.png'
import CB from '../../assets/imagens/produtos/CB.png'
import CB2 from '../../assets/imagens/produtos/CB2.png'
import SF1 from '../../assets/imagens/produtos/SF1.png'
import SF2 from '../../assets/imagens/produtos/SF2.png'
import SF3 from '../../assets/imagens/produtos/SF3.png'
import SF110 from '../../assets/imagens/produtos/SF110.png'
import SF125 from '../../assets/imagens/produtos/SF125.png'
import SF150 from '../../assets/imagens/produtos/SF150.png'
import SF160 from '../../assets/imagens/produtos/SF160.png'
import SFBros from '../../assets/imagens/produtos/SFBros.png'
import SFMini from '../../assets/imagens/produtos/SFMini.png'
import SFZ from '../../assets/imagens/produtos/SFZ.png'
import Produto from '../Produto';
import './Home.css';
function Home() {
  const title = "Street Fighter";
  const footerText = "© 2024 All rights reserved.";
  const images = [
    'https://via.placeholder.com/800x400.png?text=Slide+1',
    'https://via.placeholder.com/800x400.png?text=Slide+2',
    'https://via.placeholder.com/800x400.png?text=Slide+3'
  ];
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Street Fighter Painéis automotivo." />
      </Helmet>
      <Header title={title} />
      <main>
        <img width="100%" src={img1} alt="imagem 1"></img>
        <ul className='listaItens'>
          <li>
            <Produto produto={{nomeProduto:"Cafe Racer",valorProduto:"R$ 513,00",imagem:CafeRacer,link:"https://produto.mercadolivre.com.br/MLB-2170026291-painel-digital-original-streetfighter-cafe-racerimas-_JM"}}></Produto>
          </li>
          <li>
            <Produto produto={{nomeProduto:"CB",valorProduto:"R$ 513,00",imagem:CB,link:"https://produto.mercadolivre.com.br/MLB-3845980488-estilo-cb-painel-digital-street-fighter-c-suporte-twister-_JM"}}></Produto>
          </li>
          <li>
            <Produto produto={{nomeProduto:"CB2",valorProduto:"R$ 650,00",imagem:CB2,link:"https://produto.mercadolivre.com.br/MLB-4156649758-estilo-cb2-painel-digital-street-fighter-c-chapa-twister-_JM"}}></Produto>
          </li>
          <li>
            <Produto produto={{nomeProduto:"SF1",valorProduto:"R$ 513,00",imagem:SF1,link:"https://produto.mercadolivre.com.br/MLB-2715327896-painel-digital-original-streetfighter-sf1-_JM"}}></Produto>
          </li>
          <li>
            <Produto produto={{nomeProduto:"SF2",valorProduto:"R$ 513,00",imagem:SF2,link:"https://produto.mercadolivre.com.br/MLB-3844453488-sf2-painel-street-fighter-15000-rpm-c-sensor-magnetico-_JM"}}></Produto>
          </li>
          <li>
            <Produto produto={{nomeProduto:"SF3",valorProduto:"R$ 1150,00",imagem:SF3,link:"https://produto.mercadolivre.com.br/MLB-3840069988-sf3-painel-digital-street-fighter-c-suporte-twister-_JM"}}></Produto>
          </li>
          <li>
            <Produto produto={{nomeProduto:"SF110",valorProduto:"R$ 527,25",imagem:SF110,link:"https://produto.mercadolivre.com.br/MLB-3738204248-painel-pop-sf110-original-street-fighter-sensor-de-marchas-_JM"}}></Produto>
          </li>
          <li>
            <Produto produto={{nomeProduto:"SF125",valorProduto:"R$ 650,00",imagem:SF125,link:"https://produto.mercadolivre.com.br/MLB-3738336648-painel-digital-sf125-com-navegador-gps-street-fighter-ori-_JM"}}></Produto>
          </li>
          <li>
            <Produto produto={{nomeProduto:"SF150",valorProduto:"R$ 650,00",imagem:SF150,link:"https://produto.mercadolivre.com.br/MLB-4436738804-painel-fan-150-digital-c-navegador-gps-street-fighter-ori-_JM"}}></Produto>
          </li>
          <li>
            <Produto produto={{nomeProduto:"SF160",valorProduto:"R$ 680,00",imagem:SF160,link:"https://produto.mercadolivre.com.br/MLB-3494562613-painel-160-startstreetfighter-para-titan-160-ou-montadinha-_JM"}}></Produto>
          </li>
          <li>
            <Produto produto={{nomeProduto:"SFBros",valorProduto:"R$ 513,00",imagem:SFBros,link:"https://produto.mercadolivre.com.br/MLB-3738204232-painel-digital-sf-bros-para-honda-nxr-150-e-125-bros-origina-_JM"}}></Produto>
          </li>
          <li>
            <Produto produto={{nomeProduto:"SFMini",valorProduto:"R$ 513,00",imagem:SFMini,link:"https://produto.mercadolivre.com.br/MLB-3388892363-sf-mini-painel-digital-universal-street-fighter-original-_JM"}}></Produto>
          </li>
          <li>
            <Produto produto={{nomeProduto:"SFZ",valorProduto:"R$ 513,00",imagem:SFZ,link:"https://produto.mercadolivre.com.br/MLB-3832996928-sfz-painel-digital-street-fighter-c-suporte-para-twister-_JM"}}></Produto>
          </li>
        </ul>
      </main>
      <Footer footerText={footerText} />
    </div>
  );
}

export default Home;

//<Carousel images={images} />