// src/Produto.js
import React from 'react';
import './Produto.css';
import { Link } from 'react-router-dom';
import img1 from '../../assets/imagens/capa-site.png'
const Produto = ({ produto }) => {


  return (
    <Link className='produto' to={produto.link}>
      <div className="infoProduto">
        <img className="imagemProduto" src={produto.imagem}/>
        <div className="fundoPreto">
          <div className="nomeProduto">
            {produto.nomeProduto}
          </div>
          <div className="precoProduto">
            {produto.valorProduto}
          </div>
        </div>
      </div>
    </Link>
  );
};


export default Produto;
